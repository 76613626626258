<template>
  <b-card class="program-update-tab-card">
    <!-- Table Top -->
    <b-row v-if="profile.role === userRoles.ADMIN">
      <client-program
        v-if="isChampionPortal"
        title="Training"
        :program="program"
      />
      <client-program
        v-if="!isChampionPortal"
        :program="program"
      />
      <b-col
        cols="6"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <div>
            <b-button
              variant="primary"
              style="margin-left: 10px"
              @click="showAddTraining()"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
                size="16"
              />
              <span class="text-nowrap">Add Training</span>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- Add training to program -->
    <add-training-to-program
      v-if="isProgramTraining"
      :program="program"
      :show-modal="showModal"
      @trainingAdded="onTrainingAdded"
      @hiddenModal="hiddenModal"
    />

    <!-- Table training program -->
    <program-trainings-list
      v-if="isProgramTraining"
      ref="programTrainingsList"
      @edit="editTraining"
    />

    <!-- Add training library -->
    <b-sidebar
      v-if="!isProgramTraining"
      id="add-training-right"
      title="Add New Training"
      bg-variant="white"
      right
      backdrop
      shadow
      style="min-width: 600px !important"
      width="50%"
    >
      <div>
        <add-training
          ref="addTraining"
          @trainingAdded="onTrainingAdded"
        />
      </div>
    </b-sidebar>

    <!-- Edit training library -->
    <b-sidebar
      id="edit-training-right"
      title="Edit Training"
      bg-variant="white"
      right
      backdrop
      shadow
      style="min-width: 600px !important"
      width="50%"
    >
      <div>
        <edit-training
          ref="editTraining"
          :is-program-training="isProgramTraining"
          :training="trainingEdit"
          @trainingUpdated="onTrainingUpdated"
        />
      </div>
    </b-sidebar>

    <!-- Table training library -->
    <training-list
      v-if="!isProgramTraining"
      ref="trainingList"
      @edit="editTraining"
    />
  </b-card>
</template>

<script>
import { BButton, BRow, BCol, BCard, VBToggle, BSidebar } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import TrainingList from "./TrainingList.vue";
import AddTraining from "./AddTraining.vue";
import ProgramTrainingsList from "./ProgramTrainingsList.vue";
import AddTrainingToProgram from "./AddTrainingToProgram.vue";
import { userRoles } from "@models/userRoles";
import EditTraining from "./EditTraining.vue";
import { mapGetters } from "vuex";
import ClientProgram from '@/views/apps/admin/programs/general/ClientProgram.vue';

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    TrainingList,
    AddTraining,
    ProgramTrainingsList,
    AddTrainingToProgram,
    EditTraining,
    ClientProgram,
    BSidebar
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  props: {
    isProgramTraining: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: ''
    },
    program: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      userRoles,
      trainingEdit: {},
      showModal: false
    };
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
    ...mapGetters("app", ['isChampionPortal']),
  },
  methods: {
    showAddTraining() {
      if (this.isProgramTraining) {
        this.showModal = true;
      } else {
        this.$root.$emit("bv::toggle::collapse", "add-training-right");
      }
    },
    onTrainingAdded() {
      if (this.isProgramTraining) {
        this.$refs.programTrainingsList.loadItems();
      } else {
        this.$refs.trainingList.loadItems();
      }
    },
    onTrainingUpdated() {
      if (this.isProgramTraining) {
        this.$refs.programTrainingsList.loadItems();
      } else {
        this.$refs.trainingList.loadItems();
      }
    },
    editTraining(training) {
      this.trainingEdit = training;
      this.showEditTraining();
    },
    showEditTraining() {
      this.$root.$emit("bv::toggle::collapse", "edit-training-right");
    },
    handleShowModal() {
      this.showModal = true;
    },
    hiddenModal(isShow) {
      this.showModal = isShow;
    },
  },
};
</script>
<style lang="scss">
    $sidebar-header-text-color: #808080;
    .b-sidebar > .b-sidebar-header{
      flex-direction: row-reverse;
      background: #f3f3f3;
      color: $sidebar-header-text-color;
  
      #edit-training-right___title__ {
        font-size: .8em;
        flex: 2;
      }
      #add-training-right___title__ {
        font-size: .8em;
        flex: 2;
      }
    }
</style>