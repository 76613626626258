/* eslint-disable camelcase */
export class TrainingThumbnailClass {
  constructor ({
    id = undefined,
    training_id = undefined,
    type_id = undefined,
    filename = undefined,
    url = undefined,
    display_order = 0,
    file = undefined,
    link = '',
    client_id = undefined,
    created_at = undefined
  } = {}) {
    this.id = id;
    this.trainig_id = training_id;
    this.type_id = type_id;
    this.filename = filename;
    this.url = url;
    this.file = file;
    this.link = link;
    this.display_order = display_order;
    this.client_id = client_id;
    this.created_at = created_at;
  }
}