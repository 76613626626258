<template>
  <section>
    <b-sidebar
      id="adds-surveys-sidebar"
      v-model="openSideBar"
      :title="sideBarTitle"
      bg-variant="white"
      right
      shadow
      backdrop
      aria-controls="add-surveys-sidebar"
      :aria-expanded="openSideBar"
      width="50%"
    >
      <validation-observer
        ref="observer"
        v-slot="{ pristine, invalid, handleSubmit, errors }"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <aom-languages
            :dirty-tabs="isTabDataDirty(errors)"
          >
            <div
              v-if="inProgramDetail"
              slot="header"
              class="mb-2"
            >
              <validation-provider
                v-slot="validationContext"
                rules="required"
                vid="recipient"
                name="Recipient"
              >
                <b-form-group
                  label="Recipient"
                  label-for="recipient"
                  class="mb-0"
                >
                  <v-select
                    v-model="recipientGroup"
                    label="name"
                    :options="getGroups()"
                    :loading="isLoadingGroups"
                    placeholder="Select a Group"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    <template #option="{ name, type }">
                      <b-row>
                        <b-col sm="12">
                          <span class="mr-1"> {{ name }}</span>
                          <b-badge
                            variant="secondary"
                          >
                            {{ type }}
                          </b-badge>
                        </b-col>
                      </b-row>
                    </template>

                    <template #selected-option="{ avatar, name }">
                      <b-avatar
                        size="sm"
                        class="border border-white"
                        :src="avatar"
                      />
                      <span class="ml-50"> {{ name }}</span>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <user-roles-select
              slot="header"
              list-type="participant"
              :value="survey.roles"
              :custom-label="'Participant Role'"
              @update="updateUserRole"
            />

            <template slot-scope="props">
              <application-title-desc
                :title="surveyTitle"
                :index="props.index"
                :locale-id="props.localeId"
                :description="surveyDescription"
                @updateTitle="updateTitle"
                @updateDescription="updateDescription"
              />
            </template>
            <div slot="footer">
              <b-row>
                <b-col md="3">
                  <label class="mt-1">Show</label>
                </b-col>
              </b-row>
              <b-row>
                <!-- When -->
                <b-col
                  md="12"
                  lg="4"
                >
                  <validation-provider
                    v-slot="validationContext"
                    rules="required"
                    vid="show-when"
                    name="When"
                  >
                    <b-form-group label-for="show">
                      <v-select
                        id="show-when"
                        v-model="happenWhen"
                        label="name"
                        placeholder="When"
                        :options="eventTriggersDisplay"
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      />
                      <b-form-invalid-feedback
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      >
                        {{
                          validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Event -->
                <b-col
                  md="12"
                  lg="8"
                >
                  <validation-provider
                    v-slot="validationContext"
                    rules="required"
                    vid="show-actionable-event"
                    name="Event"
                  >
                    <b-form-group label-for="show">
                      <aom-events-select
                        id="show-actionable-event"
                        v-model="actionableEvent"
                        :options="eventsDisplay"
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      />
                      <b-form-invalid-feedback
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      >
                        {{
                          validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Custom date -->
                  <b-row v-if="showCustomDateField">
                    <b-col md="12">
                      <aom-date-picker
                        v-model="scheduledDate"
                        :min="minCustomDate"
                      />
                    </b-col>
                  </b-row>
                </b-col>

                <!-- Period -->
                <b-col
                  v-if="showPeriodField"
                  md="12"
                  lg="6"
                >
                  <validation-provider
                    v-slot="validationContext"
                    vid="time"
                    :rules="{ required: true }"
                    name="Period"
                  >
                    <b-form-group label-for="show">
                      <v-select
                        id="time"
                        v-model="period"
                        label="#"
                        placeholder="Period"
                        :options="periodList"
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      />
                      <b-form-invalid-feedback
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      >
                        {{
                          validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Duration -->
                <b-col
                  v-if="showDurationField"
                  md="12"
                  lg="6"
                >
                  <validation-provider
                    v-slot="validationContext"
                    vid="duration"
                    :rules="{ required: true }"
                    name="Duration"
                  >
                    <b-form-group label-for="show">
                      <v-select
                        id="duration"
                        v-model="duration"
                        label="name"
                        placeholder="Duration"
                        :options="durationUnitsDisplay"
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      />
                      <b-form-invalid-feedback
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      >
                        {{
                          validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </aom-languages>
          <b-card>
            <b-row>
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="reset"
                  variant="outline-dark"
                  @click="clearForm"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="submit"
                  variant="primary"
                >
                  <b-spinner
                    v-if="isUpdating" 
                    small
                  />
                  <span
                    v-if="isUpdating"
                  > Updating...</span>
                  <span v-else>Submit</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-sidebar>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BRow,
  BCol,
  BCard,
  BSidebar,
  BButton,
  BSpinner,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BBadge,
  BAvatar
} from "bootstrap-vue";

import AomLanguages from "@aom-core/AomLanguages";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import ApplicationTitleDesc from '../applications/ApplicationTitleDesc.vue';
import { FormClass, FormTranslations } from '@models/formsClass';
import formsService from '@/services/formsService';
import { formTypes } from '@models/formTypes';
import UserRolesSelect from '@/@aom-core/UserRolesSelect.vue';
import { RolesClass } from '@models/userRolesClass';
import vSelect from "vue-select";
import { getValidationState } from "@/libs/utils";
import {
  actions,
  eventTriggers,
  eventTriggersDisplay,
  durationUnitsDisplay,
  actionableEventDisplay as defaultEventsDisplay
} from '@models';
//eslint-disable-next-line
import { required } from "@validations";
import useActionableEvent from "@/views/apps/useActionableEvent.js";
import AomEventsSelect from '@/@aom-core/AomEventsSelect.vue';
import AomDatePicker from "@aom-core/AomDatePicker";

export default {
  name: 'AddSurveySideBar',
  components: {
    BCard,
    BRow,
    BCol,
    BSidebar,
    AomLanguages,
    BButton,
    BSpinner,
    BForm,
    ValidationObserver,
    ValidationProvider,
    ApplicationTitleDesc,
    UserRolesSelect,
    BFormGroup,
    BFormInvalidFeedback,
    vSelect,
    BBadge,
    BAvatar,
    AomEventsSelect,
    AomDatePicker
  },
  props: {
    inProgramDetail: {
      type: Boolean
    },
    open: {
      type: Boolean,
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data(vm) {
    return {
      sideBarTitle: 'Add New Survey',
      isUpdating: false,
      openSideBar: vm.open,
      surveyTitle : '',
      surveyDescription: '',
      eventTriggersDisplay,
      defaultEventsDisplay,
      durationUnitsDisplay,
      periodList: Array.from({length: 100}, (_, i) => i + 1),
      survey: new FormClass({roles:[], translations:[], scheduled_actions:[{ action_id: actions.SHOW }], type_id: formTypes.SURVEY}),
      isLoadingGroups: false,
      recipientGroup: null,
    };
  },
  computed: {
    ...mapGetters("programs", ["programUserGroups"])
  },
  watch: {
    open() {
      this.openSideBar = this.open;
    },
    openSideBar(n) {
      this.$emit('toggleSideBar', n);
    },
    happenWhen() {
      this.survey.scheduled_actions = [{ action_id: actions.SHOW }];
    },
    actionableEvent(n) {
      if (n) {
        this.updateScheduledEvent(n);
      }
    },
    duration(n) {
      if (n) {
        this.updateScheduledDuration(n);
      }
    },
    period(n) {
      if (n) {
        this.updateScheduledPeriod(n);
      }
    },
    scheduledDate(n) {
      if (n) {
        this.updateScheduledAbsoluteDate(n);
      }
    },
  },
  setup(props) {
    const {
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      eventsDisplay,
      minCustomDate
    } = useActionableEvent(props.inProgramDetail);

    return {
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      eventsDisplay,
      getValidationState,
      minCustomDate
    };
  },
  methods: {
    getGroups() {
      return this.programUserGroups.filter(group => !group.is_champion);
    },
    updateTitle (payload) {
      const {title, localeId} = payload;
      this.updateSurveyTranslation({title, locale_id: localeId});
    },
    updateDescription (payload) {
      const {description, localeId} = payload;
      this.updateSurveyTranslation({description, locale_id: localeId});
    },
    updateUserRole (role) {
      if(role.length > 0) {
        const targetRoles = role.map(r => new RolesClass({ id: r.id, name: r.name }));
        this.survey = {
          ...this.survey,
          roles: targetRoles
        };
      }
    },
    updateScheduledEvent(e) {
      const {id} = e;
      if(id) {
        if(this.survey.scheduled_actions.length > 0) {
          const update = this.survey.scheduled_actions.map(action => ({...action, actionable_event_id: id}));
          this.survey = {
            ...this.survey,
            scheduled_actions: update
          };
        } else {
          this.survey = {
            ...this.survey,
            scheduled_actions: [{
              actionable_event_id: id
            }]
          };
        }
      }
    },
    updateScheduledDuration(d) {
      const {id} = d;
      if(id) {
        if(this.survey.scheduled_actions.length > 0) {
          const update = this.survey.scheduled_actions.map(action => ({...action, relative_unit_id: id}));
          this.survey = {
            ...this.survey,
            scheduled_actions: update
          };
        } else {
          this.survey = {
            ...this.survey,
            scheduled_actions: [{
              relative_unit_id: id
            }]
          };
        }
      }
    },
    updateScheduledPeriod(p) {
      if (p) {
        let newP = 0;
        // Convert relative_value
        if (this.happenWhen) {
          switch (this.happenWhen.id) {
            case eventTriggers.BEFORE:
              newP = -p;
              break;
            case eventTriggers.ON:
              newP = 0;
              break;
            case eventTriggers.AFTER:
              newP = p;
              break;
          }
        }

        if(this.survey.scheduled_actions.length > 0) {
          const update = this.survey.scheduled_actions.map(action => ({...action, relative_value: newP}));
          this.survey = {
            ...this.survey,
            scheduled_actions: update
          };
        } else {
          this.survey = {
            ...this.survey,
            scheduled_actions: [{
              relative_value: newP
            }]
          };
        }  
      }
    },
    updateScheduledAbsoluteDate(date) {
      if(date) {
        if(this.survey.scheduled_actions.length > 0) {
          const update = this.survey.scheduled_actions.map(action => ({...action, actionable_event_id: null, absolute_date: date}));
          this.survey = {
            ...this.survey,
            scheduled_actions: update
          };
        } else {
          this.survey = {
            ...this.survey,
            scheduled_actions: [{
              absolute_date: date
            }]
          };
        }  
      }
    },
    closeSideBar () {
      this.openSideBar = false;
      this.$emit('close');
    },
    async onSubmit () {
      try {
        this.isUpdating = true;

        if (this.inProgramDetail) {
          this.survey.group_id = this.recipientGroup.id;
          const response = await formsService.createProgramSurvey(this.$route.params.id, this.survey);
          const { data } = response;
          if(data) {
            const { id } = data;
            this.$router.push({name: 'champion-program-survey-edit', params: {id: this.$route.params.id, survey: id} });
          }
        } else {
          await formsService.createSurvey(this.survey);
        }
        this.$emit("surveysAdded");
        this.$toast(makeSuccessToast('Survey created successfully.'));
        this.clearForm();
      } catch (e) {
        console.log(e);
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } 
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    isTabDataDirty (errors) {
      let tabErrorIndex = [];
      for (const [key, error] of Object.entries(errors)) {
        const regex = /\d+/g;
        if(error.length > 0) {
          const tabIndex = key.match(regex);
          if(Array.isArray(tabIndex)) {
            tabErrorIndex.push(tabIndex[0]);
          }
        }
      }
      return tabErrorIndex;
    },
    clearForm() {
      this.surveyTitle = '';
      this.surveyDescription =  '';
      this.survey = new FormClass({roles:[], translations:[], scheduled_actions:[{ action_id: actions.SHOW }], type_id: formTypes.SURVEY}),
      this.happenWhen = undefined;
      this.actionableEvent = undefined;
      this.period = undefined;
      this.duration = undefined;
      this.scheduledDate = undefined;
      this.closeSideBar();
    },
    updateSurveyTranslation(trans) {
      const findIndex = this.survey.translations.find(t => t.locale_id === trans.locale_id);
      if(findIndex) {
        const newArr = this.survey.translations.map(obj => {
          if (obj.locale_id === trans.locale_id) {
            return {...obj, ...trans};
          }
          return obj;
        });

        this.survey = {
          ...this.survey,
          translations: newArr
        };
      } else {
        this.survey = {
          ...this.survey,
          translations: [
            ...this.survey.translations,
            new FormTranslations(trans)
          ]
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$sidebar-header-text-color: #808080;

::v-deep .b-sidebar > .b-sidebar-header{
  flex-direction: row-reverse;
  background: #f3f3f3;
  color: $sidebar-header-text-color;

  #adds-surveys-sidebar___title__ {
    font-size: .8em;
    flex: 2;
  }
}
</style>
