<template>
  <vue-good-table
    class="mt-3"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <div
        v-if="props.column.field === 'subject'"
        cols="12"
        md="2"
        class="d-flex vgt-center-align"
      >
        <b-link
          @click="editCommunication(props.row)"
        >
          {{ props.row.subject }}
        </b-link>
      </div>
      <!-- Column: Actions -->
      <div
        v-else-if="props.column.field === 'actions'"
        cols="12"
        md="2"
        class="d-flex vgt-center-align"
      >
        <b-button
          variant="outline-none"
          @click="deleteCommunication(props.row.id)"
        >
          <feather-icon
            icon="TrashIcon"
            size="20"
          />
        </b-button>
      </div>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import {
  BButton,
  VBToggle,
  BLink
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { commsService } from '@services';
import { useGetAvailability } from "@/views/apps/useGetAvailability";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  components: {
    VueGoodTable,
    BButton,
    TablePagination,
    BLink
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Email subject",
          field: "subject",
          filterOptions: {
            enabled: true,
            placeholder: "Search email subject",
          },
          width: "15em",
        },
        {
          label: "Name (Internal)",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search name",
          },
          width: "8em",
        },
        {
          label: "Availability",
          field: "availability",
          filterOptions: {
            enabled: false,
          },
          width: "8em",
        },
        {
          field: "actions",
          sortable: false,
          width: "5em",
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  async created() {
    await this.loadItems();
    if (this.$route.query.id) {
      const comm = this.rows.find(row => Number(row.id) === Number(this.$route.query.id));
      this.$nextTick(() => {
        this.editCommunication(comm);
      });
    }
  },
  methods: {
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "subject",
        "name",
      ]) {
        if (params.columnFilters[col]) {
          if (col === "subject") {
            columnFilters.push({
              field: "messages.subject",
              value: params.columnFilters[col],
            });
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const response = await commsService.getCommsScheduled({
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });

        this.total = response.data.total;
        this.rows = response.data.items.map(i => (
          {
            ...i,
            id: i.id,
            subject: i.messages[0].subject,
            recipient: i.topic_recipients[0]?.recipient.name ?? "",
            availability: useGetAvailability(i.scheduled_action),
          }
        ));
      } catch (e) {
        this.$toast(makeErrorToast("Communications list not loaded."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    editCommunication(comm) {
      this.$nextTick(()=> {
        this.$emit('edit', comm);
      });
    },

    async deleteCommunication(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this communication? This action cannot be undone",
          {
            title: "Delete Communication",
            size: "sm",
            okVariant: "primary",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async value => {
          if (value) {
            try {
              const response = await commsService.deleteCommsScheduled(
                id
              );

              if (response) {
                this.$toast(makeSuccessToast("Communication deleted successfully."));
                this.loadItems();
              }
            } catch (e) {
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            }
          }
        });
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, 
      perPage, 
      page
    };
  },
};
</script>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
